.modalAppearance {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
}
/* @media (max-width: 600px) { */
/*mobile*/
.modalBody {
    top: 0;
    bottom: 0;
    margin: auto 0;
    border-radius: 2px;
    padding: 0;
    width: 95%;
    max-height: 82%;
    position: absolute;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    outline: transparent;
    animation: modalScrollFromBottomMobile 350ms ease-out;
    overflow-y: scroll;
}
.modalBodyCover {
    max-height: 70%;
    top: 10px;
}
/* } */
@media (min-width: 600px) {
    .modalBody {
        max-width: 566px;
    }
}

@keyframes modalScrollFromBottom {
    0% {
        top: 150%;
    }
    100% {
        top: 50%;
    }
}
@keyframes modalScrollFromBottomMobile {
    0% {
        bottom: -150%;
    }
    100% {
        bottom: 1%;
    }
}

.ReactModal__Overlay {
    background-color: rgba(107, 112, 121, 0);
    transition: all 450ms ease-in-out;
    z-index: 2;
}

.ReactModal__Overlay--after-open {
    background-color: rgba(107, 112, 121, 0.4);
    backdrop-filter: blur(2px);
}

.ReactModal__Overlay--before-close {
    background-color: transparent;
    opacity: 0;
}

/* .ModalBodySummaryMobile {
    top: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    margin-top: 15px;
    border-radius: 2px;
    padding: 0;
    max-width: 566px;
    position: absolute;
    left: 50%;
    background-color: #fff;
    transform: translate(-50%, -50%);
    outline: transparent;
    animation: modalScrollFromBottom 350ms ease-out;
} */
