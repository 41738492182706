* {
    margin: 0;
    padding: 0;
}
.App {

}
input[type="radio"] {
    -webkit-appearance: radio !important;
}
.Collapsible {
 width: 100%;
 margin-bottom: 4px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
#react-burger-menu-btn {
    display: none !important;
    height: 0px !important;
    width: 0px !important;
    z-index: -999 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.progress-section {
    position: sticky;
    top: 0;
    z-index: 100;
    height: 200px;
    transition: height 2s;
}

.progress-section-scrolled {
    height: 100px;
}