.bm-menu-wrap {
    position: absolute;
    top: 0;
}
.bm-cross-button {
    display: none !important;
    height: 0px !important;
    width: 0px !important;
}
.bm-burger-button {
    display: none !important;
    height: 0px !important;
    width: 0px !important;
}
