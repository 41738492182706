.qrCodeContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.qrCodeInput {
    width: 40px;
    height: 53px;
    border: 1px solid #2699FB;
    border-radius: 4px;
    margin: 0px 2px;
    text-align: center;
    font-size: 28px;
}