.smsCodeContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.smsCodeInput {
    width: 58px;
    height: 73px;
    border: 1px solid #cdd4d9;
    border-radius: 4px;
    margin: 0px 3px;
    text-align: center;
    font-size: 38px;
}
@media (max-width: 800px) {
    .smsCodeInput {
        height: 54px;
        width: 46px;
    }
}
